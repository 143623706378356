.word-ai-editor {
    font-family: Arial, sans-serif;
    height: 100%;
    background-color: #f0f4f8;
  .container {
    display: flex;
    height: 100%;
  }
  .sidebar {
    width: 300px;
    background-color: #2c3e50;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
  .main-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
  }
  .document {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    min-height: 1000px;
    max-width: 800px;
    margin: 0 auto;
  }
  h1,
  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #34495e;
    padding: 20px 0px;
  }
  select,
  button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: none;
  }
  button {
    background-color: #3498db;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  button:hover {
    background-color: #2980b9;
  }
  .ai-content-box {
    border: 1px solid #bdc3c7;
    background-color: #ecf0f1;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
  }
  .toolbar {
    background-color: #ecf0f1;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .toolbar button {
    width: auto;
    margin-right: 5px;
    padding: 5px 10px;
    background-color: #95a5a6;
  }
  .toolbar button:hover {
    background-color: #7f8c8d;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;
  }
  th,
  td {
    border: 1px solid #bdc3c7;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #ecf0f1;
  }
  .chat-box {
    border: 1px solid #bdc3c7;
    background-color: #ecf0f1;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
  }
  .chat-box p{
    margin: 5px 0px;
  }
  .chat-messages {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  .chat-input {
    display: flex;
    align-items: center;
  }
  .chat-input input {
    flex-grow: 1;
    padding: 5px;
    border: 1px solid #bdc3c7;
    border-radius: 3px;
  }
  .chat-input button {
    margin: 5px;
    padding: 5px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    width: 100px;
  }
  .actions {
    display: flex;
  }
  .actions button {
    width: 100px;
    padding: 5px;
    margin: 5px;
  }
  .reminder-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .reminder-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
  }
  .reminder-link {
    color: #3498db;
    text-decoration: underline;
    cursor: pointer;
  }
  .subjective {
    color: #6a5acd;
  }
  .unverifiable {
    color: #ff8c00;
  }
  .contradictory {
    color: #dc143c;
  }
  .invented {
    color: #8e44ad;
  }
  .relation mark {
    background-color: #27ae60;
    color: white;
  }
  .relation del {
    color: #c0392b;
  }
  .entity mark {
    background-color: #f39c12;
    color: white;
  }
  .entity del {
    color: #7f8c8d;
  }
}
