@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --offWhite: #000;
}

@layer base {
  html {
    font-family: "M PLUS Rounded 1c", system-ui, sans-serif;
    
  }
  
  

  /* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
}

