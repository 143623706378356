@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

:root {
  --primary: #6c63ff;
  --secondary: #ff6584;
  --tertiary: #4caf50;
  --quaternary: #ffc107;
  --quinary: #03a9f4;
  --senary: #9c27b0;
}

.agent {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }

  background-color: #000000;
  color: #ffffff;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }

  header {
    background: linear-gradient(135deg, var(--primary), var(--secondary));
    color: white;
    padding: 2rem 0;
    text-align: center;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  h2 {
    font-weight: bold;
    font-size: 1.5rem;
  }

  h3{
    font-weight: bold;
    font-size: 1.2rem;  
  }
  .tool-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
  }

  .tool-card {
    background: #1a1a1a;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

  .tool-card:hover {
    transform: translateY(-5px);
  }

  .tool-header {
    padding: 1.5rem;
    color: white;
  }

  .tool-content {
    padding: 1.5rem;
  }

  .tool-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .tool-description {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .tool-button,
  .page-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: var(--primary);
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }

  .tool-button:hover,
  .page-button:hover {
    background-color: var(--secondary);
  }

  .additional-pages {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .page-button {
    margin: 0 1rem;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal-content {
    background-color: #1a1a1a;
    padding: 20px;
    max-width: 100%;
    border-radius: 0.5rem;
    color: #ffffff;
  }

  .close {
    color: #ffffff;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #cccccc;
    text-decoration: none;
    cursor: pointer;
  }

  .agent-info h3 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  #regulatoryWorkHistory,
  #writerWorkHistory,
  #reviewWorkHistory,
  #qcWorkHistory,
  #statPlanWorkHistory,
  #fdaResponseWorkHistory {
    background-color: #2a2a2a;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;
  }

  .workflow-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem;
  }

  .reorg-button-container {
    flex: 0 0 auto;
    margin-right: 1rem;
  }

  .workflow-visualization {
    flex: 2;
    margin-right: 2rem;
  }

  .recent-tasks {
    flex: 1;
    background-color: #1a1a1a;
    padding: 1rem;
    border-radius: 15px;
  }

  .recent-tasks h2 {
    margin-bottom: 1rem;
  }

  .task-list {
    list-style-type: none;
  }

  .task-list li {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    background-color: #2a2a2a;
    border-radius: 5px;
  }

  .task-priority {
    font-weight: bold;
    margin-right: 0.5rem;
  }

  .workflow-visualization h2 {
    text-align: center;
    margin-bottom: 1rem;
  }

  #workflowSvg {
    display: block;
    margin: 0 auto;
  }

  .node {
    fill: var(--primary);
    stroke: #ffffff;
    stroke-width: 2px;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      r: 30;
    }
    50% {
      r: 35;
    }
    100% {
      r: 30;
    }
  }

  .edge {
    stroke: var(--secondary);
    stroke-width: 2px;
    stroke-dasharray: 10;
    animation: flow 1s linear infinite;
  }

  @keyframes flow {
    to {
      stroke-dashoffset: -20;
    }
  }

  .node-text {
    font-size: 12px;
    fill: #ffffff;
    text-anchor: middle;
    dominant-baseline: middle;
  }
}
